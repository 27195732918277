import { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLoggedIn, useWindowSize } from 'bv-hooks';
import { t } from 'bv-i18n';
import { Icon } from 'bv-components';
import withLazyLoad from 'bv-lazy-load';
import { enableById, disableById } from 'bv-services/body-scroll-manager';
import { ScrollableContext } from 'bv-contexts';
import withVisibility from '../hocs/with_visibility';
import { hide } from '../helpers';

const SportsSportsbookCashout = withLazyLoad()('sportsbookCashout');

const Cashout = ({ visible }) => {
  const loggedIn = useLoggedIn();
  const windowSize = useWindowSize();
  const scrollableRef = useContext(ScrollableContext);
  const modalScrollableRef = useRef(null);

  useEffect(() => {
    if (visible) {
      disableById('right-sidebar-scrollable');
    }

    return () => {
      enableById('right-sidebar-scrollable');
    };
  }, [visible]);

  return (
    <div className="modal_wrapper">
      <div className="modal_header">
        <div className="modal_header_info_wrapper" />
        <div className="modal_title_wrapper">
          <span className="modal_title">{t('javascript.cashout.cashout')}</span>
        </div>
        <span className="close">
          <span className="close-icon" onClick={hide}>
            <Icon id="close" big active />
          </span>
        </span>
      </div>
      <div className="modal_contents_wrapper" id="right-sidebar-scrollable" ref={modalScrollableRef}>
        <div className="modal_contents">
          {loggedIn && (
            <SportsSportsbookCashout
              scrollableRef={windowSize.isSmall ? modalScrollableRef : scrollableRef}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Cashout.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default withVisibility('cashout')(Cashout);
