import { compose, partial } from 'underscore';
import {
  enableById,
  disableById,
  enableByClassName,
  disableByClassName,
} from 'bv-services/body-scroll-manager';

import {
  hide as doHide,
  setTitle as doSetTitle,
  show as doShow,
  set,
} from './duck';

const { store } = window.reduxState;

const show = () => {
  store.dispatch(doShow());

  // iOS Scroll hacks
  // when moving between different components that has their own
  // scroll-lock-targets, those won't come into existence properly
  // at first render, so these calls need to be delayed
  window.setTimeout(() => {
    disableById('right-sidebar-scrollable');
    disableByClassName('right-sidebar-scrollable');
  }, 5);
};

export const hide = () => {
  enableById('right-sidebar-scrollable');
  enableByClassName('right-sidebar-scrollable');

  store.dispatch(doHide());
};

export const setTitle = compose(store.dispatch, doSetTitle);

export const setComponent = compose(store.dispatch, set);

export const showComponent = compose(show, setComponent);
export const showBetslip = partial(showComponent, 'betslip');
export const showCashout = partial(showComponent, 'cashout');
