import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeIsActive } from '../selectors';

export default (componentName) => (WrappedComponent) => {
  class WithVisibilityComponent extends Component {
    constructor(props) {
      super(props);

      this.props = props;
      this.shouldRender = props.active;
    }

    // ========================================================
    // We do not render anything until the section goes active
    // Once rendered we hide it, we avoid not needed requests
    // ========================================================
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.active) {
        this.shouldRender = true;
      }
    }

    render() {
      const { active } = this.props;
      if (this.shouldRender) {
        return (
          <div style={active ? {} : { display: 'none' }}>
            <WrappedComponent {...this.props} />
          </div>
        );
      }

      return null;
    }
  }

  WithVisibilityComponent.propTypes = {
    active: PropTypes.bool.isRequired,
  };

  const isActive = makeIsActive();

  const mapStateToProps = (state) => ({
    active: isActive(state, componentName),
  });

  WithVisibilityComponent.displayName = 'withVisibilityComponent';

  return connect(mapStateToProps)(WithVisibilityComponent);
};
