import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ScrollableContext } from 'bv-contexts';
import { useLoggedIn } from 'bv-hooks';

// Using modal classes as layout, easily replaceable
const Layout = ({ children, component, visible }) => {
  const loggedIn = useLoggedIn();
  const scrollableRef = useRef();

  const className = classnames(
    'right-sidebar has-scroll-bars right-sidebar-layout betslipV2',
    component,
    {
      visible,
      'site-header--logged-in': loggedIn,
    },
  );

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0;
    }
  }, [component]);

  return (
    <div className={className} ref={scrollableRef}>
      <ScrollableContext.Provider value={scrollableRef}>
        {children}
      </ScrollableContext.Provider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  component: PropTypes.string.isRequired,
};

export default Layout;
