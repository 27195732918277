import withLazyLoad from 'bv-lazy-load';
import { hasSportsbook } from 'global-config';
import withVisibility from '../hocs/with_visibility';

const SportsbookBetslip = hasSportsbook
  ? withLazyLoad()('sportsbookBetslip')
  : () => null;

const Betslip = () => <SportsbookBetslip betslipType="full-betslip" />;

export default withVisibility('betslip')(Betslip);
