import { useContext } from 'react';
import { useFetch, useLoggedIn } from 'bv-hooks';
import { ScrollableContext } from 'bv-contexts';
import { Spinner } from 'bv-components';
import fetchComponents from '../../api';
import componentTypes from './casino_section_components';

/**
 * Right Sidebar section containing Right components
 * @returns {React.Component}
 */
const RightComponents = () => {
  const loggedIn = useLoggedIn();
  const [components, loading] = useFetch(fetchComponents, [loggedIn]);
  const scrollableContext = useContext(ScrollableContext);

  if (loading) return <Spinner />;

  return (
    <section className="right-sidebar-components">
      {components.map(({ type, id, ...rest }) => {
        const Component = componentTypes[type];

        if (Component && rest.payload) {
          const tracking = {
            type,
            path: 'right_components',
            label: rest.payload.label,
            category: rest.payload.category,
            gameSearchCategoryId: rest.payload.gameSearchCategoryId,
          };

          return (
            <Component
              key={id}
              componentId={id}
              scrollableRef={scrollableContext}
              tracking={tracking}
              {...rest}
              labelTag="p"
            />
          );
        }

        return null;
      })}
    </section>
  );
};

export default RightComponents;
