import PropTypes from 'prop-types';
import { property } from 'underscore';
import { connect } from 'react-redux';
import { isDesktop } from 'bv';

import Layout from './layout';
import Betslip from './betslip';
import Cashout from './cashout';
import RightComponents from './right_components';

const RightSidebarApp = ({ component, visible }) => (
  <Layout component={component} visible={visible}>
    <Betslip />
    <Cashout visible={visible} />
    { isDesktop() && <RightComponents /> }
  </Layout>
);

RightSidebarApp.propTypes = {
  visible: PropTypes.bool.isRequired,
  component: PropTypes.string.isRequired,
};

export default connect(property('rightSidebar'))(RightSidebarApp);
