import App from './components/app';
import reducer from './duck';

// eslint-disable-next-line import/no-webpack-loader-syntax
require('expose-loader?RightSidebar!./helpers');

const { addReducers } = window.reduxState;

addReducers({
  rightSidebar: reducer,
});

export default App;
