import { GamesListComponent } from './casino_section_components';

const GamesListComponentContainer = (props) => (
  <GamesListComponent
    {...props}
    useLargeImages
    orientation="vertical"
    className="right-sidebar-components__casino-games-list"
    isRightSidebar
  />
);

export default GamesListComponentContainer;
