import { LiveCasinoGamesListComponent } from './casino_section_components';

const LiveCasinoGamesListComponentContainer = (props) => (
  <LiveCasinoGamesListComponent
    {...props}
    useLargeImages
    forceWideTile
    orientation="vertical"
    className="right-sidebar-components__casino-games-list"
    isRightSidebar
  />
);

export default LiveCasinoGamesListComponentContainer;
